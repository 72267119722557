/** @jsx jsx */
import { jsx } from "theme-ui"
// import React from 'react'
import { GatsbyImage } from "gatsby-plugin-image"
import { Link } from "gatsby"
import { kebabCase } from 'lodash'
import Anim from "./Anim"
import { RiTimeLine, RiUserLine, RiPriceTag3Line } from "react-icons/ri"
import { IconContext } from "react-icons";


const PostCard = ({ post }) => {
  const { title, date, author, slug, tags } = post.frontmatter

  const img = post.frontmatter.image.childImageSharp.gatsbyImageData

  return (
      <Anim>
        <div sx={ themeStyles.postCard }>
          <Link to={slug} sx={ themeStyles.cardImage } >
            <GatsbyImage image={img} />
          </Link>
          <div sx={ themeStyles.postContent } >
            <Link to={slug}><h2>{title}</h2></Link>
            <p>{post.excerpt}</p>
            <div>
            <IconContext.Provider value={{ color: "text", size: 12, className: "global-class-name" }}>
            <span><RiUserLine /> {author}</span> | <span><RiTimeLine /> {date}</span> | <span>{tags && tags.map((tag) => (
              <span key={tag}>
                <Link className="tag" to={`/tags/${kebabCase(tag)}/`}>
                  <RiPriceTag3Line /> {tag} 
                </Link>&nbsp;
              </span>
            ))}</span>
          </ IconContext.Provider>            
            </div>
          </div>
        </div>
      </Anim>
  )
}

export default PostCard

const themeStyles = {
  postCard: {
    width: `100%`,
    maxWidth: `100%`,
    mb: `1.5rem`,
    backgroundColor: "var(--theme-ui-colors-backgroundLighten10)",
    display: `flex`,
    flexDirection: [`column`, `row`, `row`, `row`],
    alignItems: `stretch`,
    minHeight: `11rem`,
    borderRadius: `10px`,
    overflow: `hidden`,
    transition: `all .3s ease`,
    boxShadow: `0 1px 1px 0 rgba(31, 35, 46, .15)`,
    "&:hover": {
      transform: "translate(0px, -2px)",
      boxShadow: "0 15px 45px -10px rgba(10, 16, 34, .2)",
    },
  },
  cardImage: {
    width: [`100%`, `30%`, `30%`, `30%`],
    maxWidth: `100%`,
    backgroundSize: `cover`,
    backgroundPosition: `50% 50%`,
    position: "relative",
    padding: "0",
    margin: "0",
    maxHeight: "200px",
    backgroundColor: "background",
    overflow: "hidden",
    // figure: {
    //   position: "relative",
    //   margin: "0",
    // },
  },
  postContent:{
    padding: `.5rem 1rem`,
    width: [`100%`, `70%`, `70%`, `70%`],
  },
  postTitle: {
    margin: `0 0 10px`,
    fontSize: `30px`,
    fontWeight: 400,
    a: {
      fontFamily: "'PT Serif', serif",
      textDecoration: `none`,
      color: `text`,
    }
  }
}  