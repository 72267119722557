import * as React from "react"
import Layout from "../components/Layout"
import PostList from "../components/PostList"
import { useStaticQuery, graphql } from "gatsby"

// styles
const pageStyles = {
  //  color: "#232129",
    padding: "2px",
    fontFamily: "'PT Serif', Lato, source-han-serif, -apple-system, Roboto, sans-serif, serif",
  }

const getPosts = graphql`
  {
    allMdx(sort: { fields: frontmatter___date, order: DESC }) {
      totalCount
      edges {
        node {
          frontmatter {
            title
            slug
            date(formatString: "MMMM Do, YYYY")
            author
            tags
            image {
              childImageSharp {
                gatsbyImageData(
                  width: 600
                  placeholder: BLURRED
                  formats: [AUTO, WEBP]
                )
              }
            }
          }
          excerpt(truncate: true, pruneLength: 90)
        }
      }
    }
  }
`

// markup
const IndexPage = () => {
  const response = useStaticQuery(getPosts)

  const posts = response.allMdx.edges

  return (
    <Layout>
    <main style={pageStyles}>
      <PostList posts={posts} />
    </main>
    </Layout>
  )
}

export default IndexPage
