import React from "react"
import PostCard from "./PostCard"

import Anim from "./Anim"


const PostList = ({ posts }) => {
  return (
      <section>            
          {posts.map(({ node }, index) => {
            return <Anim><PostCard key={index} post={node} /></Anim>
          })}

      </section>
  )
}

export default PostList
